var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"widget text-center",staticStyle:{"color":"var(--text)"},attrs:{"title":"Temps de réponse moyen de vos sites web"}},[(_vm.watchedUrls.length > 0 && _vm.values.length > 0 && this.isLoaded)?_c('line-chart',{staticClass:"chart",attrs:{"library":{
            "colors": _vm.chartColors.colors,
            "plotOptions": {
                "series": {
                    "marker" :{"enabled": false}
                }
            },
            "xAxis": {
                "labels" :{
                    "style": {"color": _vm.chartColors.font}
                }
            },
            "yAxis": {
                "labels" :{
                    "style": {"color": _vm.chartColors.font}
                }
            },
            "tooltip": {
                "valueDecimals": 2
            },
            "chart": {
                "backgroundColor": _vm.chartColors.bg
            }
        },"legend":false,"data":_vm.values,"height":"300px","min":0,"max":_vm.maxValue,"suffix":"s","xmin":new Date(Object.keys(_vm.values[0].data)[0]),"xmax":new Date(Date.now())}}):_c('div',{staticClass:"text-center pt-3"},[(!_vm.isLoaded)?_c('b-icon',{staticClass:"mt-5",attrs:{"icon":"arrow-clockwise","animation":"spin","font-scale":"9"}}):_c('span',{staticClass:"hidden"},[_vm._v(" "+_vm._s(_vm.hideBecauseEmpty()))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }